import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import { IResponse } from "@/models/IResponse";
import { IUser } from "@/models/IUser";
import { IUserCreateRequest } from "@/models/IUserCreateRequest";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class UserService {
  data: any;
  responsedata: any;

  saveUser(user: IUserCreateRequest, key: string): Promise<IResponse> {
    this.data = axios
      .post<IResponse>(API_URL + "Account/createuser", user, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.data;
  }

  deleteUser(userId:number, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "account/deleteuser", {Id:userId}, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  updateUserStatus(userId: Number, status: boolean, key: string): Promise<IResponse> {

    let request = {
      userId: userId,
      status: status,
  }


    this.data = axios
      .post<IResponse>(API_URL + "Account/updateuserstatus", request, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.data;
  }

  getListofUser(key: string): Result {
    var URL =
      API_URL +
      "account/getallusers"

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader(key) })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getUserByUserId(
    userId: number, key: string
  ): Promise<IUser> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "account/getuserbyuserid?id=" +
          userId,
        { headers: auhtHeader(key) }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new UserService();
