import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import UserService from "@/services/UserService";
import { IUser } from "@/models/IUser";
import { TheMask } from "vue-the-mask";
import { namespace } from "vuex-class";
import SiteService from "@/services/SiteService";
import { IUserCreateRequest } from "@/models/IUserCreateRequest";

const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    Multiselect,
    TheMask,
  },
})
export default class UserModule extends Vue {
  siteKey = "xxxx2589";
  isloaded = false;
  loading = false;
  users: any = [];
  totalItems = 0;
  current_page = 1;
  selectedBranch: any = {};
  value: any = [];
  siteForPermission: any = [];
  search = "";
  active = false;
  message = "";
  inputValid = true;
  result: any = {};
  userType = [
    { UserType: "Administrator", UserTypeId: 1 },
    { UserType: "User", UserTypeId: 2 },
    { UserType: "SEO", UserTypeId: 3 },
  ];

  branchesPrermission: any = [];
  selectedUserType: any = {};
  user: IUser = {
    userId: 0,
    userLogin: "",
    password: "",
    userType: "",
    userName: "",
    isActive: true,
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 15,
    totalElements: 0,
  };
  delCatId: number = 0;
  activePrompt: boolean = false;
  isFailed: boolean = false;
  failedMessage: string = "";

  @Auth.Getter
  private getBranchId!: string;

  async created() {
    (this as any).$Progress.start();
    var result = await UserService.getListofUser(this.siteKey);

    if (result) {
      this.isloaded = false;
      this.users = result;
      this.siteForPermission = await SiteService.getListofSite(this.siteKey);
      console.log(this.siteForPermission);
      this.isloaded = true;
    }
    (this as any).$Progress.finish();
  }

  // @Watch("getBranchId")
  // onGetBranchIdChanged(value: string, oldValue: string) {
  //   this.setTable(1)
  // }

  async setTable(pagenumber: number) {
    this.users = [];
    (this as any).$Progress.start();
    var result = await UserService.getListofUser(this.siteKey);

    if (result) {
      this.isloaded = false;
      this.users = result;
      this.isloaded = true;
    }

    (this as any).$Progress.finish();
  }

  @Watch("users")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true;
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page);
    //console.log(this.current_page)
  }

  onBack() {
    this.active = !this.active;
  }

  async onOpen() {
    this.active = !this.active;
    this.value = []
    this.user = {
      userId: 0,
      userLogin: "",
      password: "",
      userType: "",
      userName: "",
      isActive: true,
    };
  }

  openConfirm(catId: number) {
    // (this as any).$vs.dialog({
    //   type: "confirm",
    //   color: "danger",
    //   title: `Confirm`,
    //   text: "Are you sure you want to delete category?",
    //   accept: this.deleteCategory(catId),
    //   acceptText: "Yes",
    //   cancelText: "No",
    // });
    this.delCatId = catId;
    this.activePrompt = true;
  }

  deleteCategory(userId: number) {
    (this as any).$Progress.start();

    console.log(userId);
    UserService.deleteUser(this.delCatId, this.siteKey).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Users",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Users",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
          (this as any).$Progress.finish();
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Users",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  onStatusChange(status: boolean, userId: number) {
    (this as any).$Progress.start();
    UserService.updateUserStatus(userId, !status, this.siteKey).then(
      (data) => {
        (this as any).$vs.notify({
          title: "User Status",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New User",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  async onEdit(userId: number) {
    (this as any).$Progress.start();
    this.result = await UserService.getUserByUserId(userId, this.siteKey);

    this.user = this.result.userMdl;
    this.value = []
    this.selectedUserType = this.userType.find(
      (x) => x.UserType === this.user.userType
    );


    for (var i = 0; i < this.result.userSiteId.length; i++) {
      let tag: any = {
        siteId: this.result.userSiteId[i],
        siteName: this.siteForPermission.find(
          (x:any) => x.siteId == this.result.userSiteId[i]
        ).siteName
      };

      this.value.push(tag);

      this.active = !this.active;
      (this as any).$Progress.finish();
    }
  }



  onSubmit() {
    this.user.userType = this.selectedUserType.UserType;
    var siteId: any = [];

    var req: IUserCreateRequest = {
      userMdl: {
        userId: 0,
        userLogin: "",
        password: "",
        userType: "",
        userName: "",
        isActive: true,
      },
      userSiteId: [],
    };

    req.userMdl = this.user;
    for (var i = 0; i < this.value.length; i++) {
      req.userSiteId.push(this.value[i].siteId);
    }
    //req.userSiteId = siteId

    (this as any).$Progress.start();
    UserService.saveUser(req, this.siteKey).then(
      (data) => {
        if (data.statusCode == 200) {
          this.loading = false;
          this.setTable(1);
          (this as any).$vs.notify({
            title: "New User",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
          if (this.user.userId == 0) {
            this.user = {
              userId: 0,
              userLogin: "",
              password: "",
              userType: "",
              userName: "",
              isActive: true,
            };
          }
        } else {
          this.loading = false;
          (this as any).$Progress.finish();
          this.message = data.message;
          this.inputValid = true;
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New User",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
